<script setup lang="ts">

import { ref, onMounted } from 'vue'

const props = defineProps({
    context: {
        type: Object,
        required: true
    }
})


const tmp = ref(props.context._value || '')

const digits = Number(props.context.digits)

function handleInput(index: number, e: Event) {
    if (!e.target) return
    const _target = e.target as HTMLInputElement
    const prev = tmp.value

    if (tmp.value.length <= index) {
        tmp.value = '' + tmp.value + _target.value
    } else {
        tmp.value = '' + tmp.value.substr(0, index) + _target.value + tmp.value.substr(index + 1)
    }

    const inputs = _target.parentElement!.querySelectorAll('input')

    if (index < digits - 1 && tmp.value.length >= prev.length) {
        // If this is a new input and not at the end, focus the next input
        inputs.item(index + 1).focus()
    } else if (index > 0 && tmp.value.length < prev.length) {
        // in this case we deleted a value, focus backwards
        inputs.item(index - 1).focus()
    }

    if (tmp.value.length === digits) {
        // If our input is complete, commit the value.
        props.context.node.input(tmp.value)
    } else if (tmp.value.length < digits && props.context.value !== '') {
        // If our input is incomplete, it should have no value.
        props.context.node.input('')
    }
}
/**
 * On focus, select the text in our input.
 */
function handleFocus(e: FocusEvent) {
    if (!e.target) return
    (e.target as HTMLInputElement).select()
}

/**
* Handle the paste event.
*/
function handlePaste(e: ClipboardEvent) {
    if (e.clipboardData === null || !e.target) return
    const paste = e.clipboardData.getData('text')
    if (typeof paste === 'string') {
        //Get all the inputs
        const inputs = (e.target as HTMLInputElement).parentElement!.querySelectorAll('input')
        //Focus on the first input. Needed for Firefox
        inputs.item(0).focus()
        // If it is the right length, paste it.
        tmp.value = paste.substr(0, digits)
        //Firefox timing issue. Leave this else last character will be overwritten by the first character of the paste
        setTimeout(
            // Focus on the last character
            () => inputs.item(tmp.value.length - 1).focus()
            , 10)
    }
}

// focus the first digit on mounted
onMounted(() => {
    const inputs = document.querySelectorAll('input.formkit-digit') as NodeListOf<HTMLInputElement>
    inputs.item(0).focus()
})

</script>

<template>
    <input type="text" @input="handleInput(index - 1, $event)" @focus="handleFocus" @paste="handlePaste" maxlength="1"
        size="1" v-for="index in digits" :class="context.classes.digit" :value="tmp[index - 1] || ''"
        :key="`digit-${index}`">
</template>

<style>
.formkit-digit {
    font-size: 1.5rem;
    padding-inline: 0.5rem;
    padding-block: 0.75rem;
    color: black;
    text-align: center;
    width: 1.5ch;
    box-sizing: content-box !important;
}
</style>