import { ref } from 'vue';

export function useUserAgent() {
    const userAgent = ref(navigator.userAgent);

    const isMobile = ref(/Mobile|Android|iP(hone|od|ad)/.test(userAgent.value));
    const isChrome = ref(/Chrome/.test(userAgent.value));
    const isFirefox = ref(/Firefox/.test(userAgent.value));
    const isSafari = ref(/Safari/.test(userAgent.value) && !isChrome.value);

    return { userAgent, isMobile, isChrome, isFirefox, isSafari };
}