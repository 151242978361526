<script setup lang="ts">
import apiCall from '@/api/apiCall';
import { apiCallWrap } from '@/utilities/errorHandler';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import { useUserAgent } from '@/utilities/userAgent';
const { isSafari } = useUserAgent();

import { useAuthStore } from '@/stores/authStore';
import { useDeclarationUnit } from '@/stores/declarationUnit';
import { storeToRefs } from 'pinia';

const { t } = useI18n();
const router = useRouter();

const toast = useToast();
const authStore = useAuthStore();
const DUStore = useDeclarationUnit();
const { user } = storeToRefs(authStore);

const emit = defineEmits<{
    logOut: [];
}>();

function reset() {
    apiCallWrap(async () => {
        let res = await apiCall('/reset', 'post', null, null);
        if (!res) throw new Error();
        toast.success(t('app.usermenu.resetsuccess'))
        DUStore.refreshAllDeclarations();
        router.push({ name: 'declarations' })
    })
}

const showUserMenu = import.meta.env.VITE_X_BRANCH !== 'production';
</script>

<template>
    <div class="user-menu u-flex u-flex-column u-gap-2">
        <div class="user-menu__head u-flex u-gap-2" :class="{ 'd-block-safari': isSafari }">
            <i class="fa-solid fa-sharp fa-user"></i> <span class="unfocus-hide">{{ user.name }}</span>
        </div>
        <ul class="unfocus-hide" :class="{ 'd-block-safari': isSafari }">
            <template v-if="DUStore.activeDUID">
                <li v-if="showUserMenu">
                    <ArrowLink @click="() => reset()" :label="t('app.usermenu.reset')"></ArrowLink>
                </li>
                <li>
                    <ArrowLink :to="{ name: 'users' }" :label="t('app.usermenu.users')"></ArrowLink>
                </li>
            </template>
            <li>
                <ArrowLink :label="t('app.usermenu.signout')" @click="() => emit('logOut')"></ArrowLink>
            </li>
        </ul>
    </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/utilities" as u;

.user-menu {
    background-color: white;
    padding: 1rem;
    border-radius: 7px;
    z-index: 2;

    &:not(:focus-within, :hover) .unfocus-hide {
        // display: none;
        @include u.sr-only();
    }
}

/* Fix for safari showing the user menu inside the viewport */
.d-block-safari {
    display: --webkit-block !important;
    display: block !important;
}
</style> 