<script setup lang="ts">
    const props = withDefaults(defineProps<{
        label?: string;
        to?: string | { name: string };
        'prefixIcon'?: icon;
        'suffixIcon'?: icon;
        bsize?: 'small'
        bstyle?: 'primary' | 'secondary' | 'neutral' | 'destructive'
    }>(), {
        bstyle: 'primary'
    })

    type icon = 'plus' | 'pencil' | 'save' | 'xmark' | 'trash' | 'chevron-right' | 'chevron-left' | 'signature' | 'users' | 'circle-notch' | 'copy';

</script>

<template>
    <button v-if="!props.to"
            class="c-action-button u-p0 u-focusable"
            :class="[{ [`size-${props.bsize}`]: props.bsize }, { [`style-${props.bstyle}`]: props.bstyle }, {
                'prefix-spinner': props.prefixIcon === 'circle-notch'
            }]">
        <FaIcon :icon="props.prefixIcon"
                v-if="props.prefixIcon" />
        <slot name="label"
              :label="props.label"><span v-if="props.label">{{ props.label }}</span></slot>

        <FaIcon :icon="props.suffixIcon"
                v-if="props.suffixIcon" />
    </button>
    <RouterLink v-else
                :to="props.to"
                class="c-action-button u-p0 u-focusable"
                :class="[{ [`size-${props.bsize}`]: props.bsize }, { [`style-${props.bstyle}`]: props.bstyle }]">
        <FaIcon :icon="props.prefixIcon"
                v-if="props.prefixIcon" />
        <slot name="label"
              :label="props.label"><span v-if="props.label">{{ props.label }}</span></slot>

        <FaIcon :icon="props.suffixIcon"
                v-if="props.suffixIcon" />
    </RouterLink>
</template>

<style lang="scss" scoped>
.c-action-button {
    --p-inline: 0.75rem;
    --p-block: 0.5rem;
    --style-bg-color: var(--color-primary);
    --style-border-color: transparent;
    --style-color: white;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    border: 1px solid var(--style-border-color);
    background-color: var(--style-bg-color);
    color: var(--style-color);
    font-weight: bold;
    font-size: inherit;
    font-family: inherit;
    display: inline-flex;
    gap: 0.5rem;
    cursor: pointer;
    padding-inline: var(--p-inline);
    padding-block: var(--p-block);

    &.style-primary {
        --style-bg-color: var(--color-primary);
    }

    &.style-secondary {
        --style-bg-color: var(--color-secondary);
    }

    &.style-neutral {
        --style-bg-color: var(--color-neutral);
        --style-color: black;
    }

    &.style-destructive {
        --style-bg-color: color-mix(in oklch, tomato, transparent);
        --style-border-color: tomato;
        --style-color: black;
    }

    &.size-small {
        --p-inline: 0.5rem;
        --p-block: 0.25rem;
        font-weight: normal;
    }

    &[disabled] {
        cursor: not-allowed;
        --style-bg-color: var(--tt-slate-200);
        --style-color: var(--tt-slate-400);
        --style-border-color: transparent;
    }

    &.prefix-spinner:disabled {
        :deep(i:first-child) {
            animation: spin 1s linear infinite;
        }
    }
}
</style> 



